import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HomeHero = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "home-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        className="img"
      />

      <h2 className="text">Live Life Activated</h2>
    </Wrapper>
  )
}

export default HomeHero

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  .img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 0;
  }
  .text {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    text-align: center;
    font-family: var(--trim);
    font-size: 5vw;
    color: var(--blue);
    margin: 0;
    @media (max-width: 768px) {
      font-size: 7vw;
    }
  }
`
